<template>
     <div class="subAccount">
          <div class="title">
               创建子账号
          </div>
          <div class="create_sub_acc_con">
               <table id="popTable">
                    <tr class="row1">
                         <td class="td_title"><i>*</i><span>手机号码：</span></td>
                         <td>
                              <el-input type="number" placeholder="请输入手机号码" v-model="subAccountForm.phone"
                                   @input="watchContent('phone')" @blur="checkDo('phone')">
                              </el-input>
                         </td>
                         <td>
                              <span v-show="formCheckTip.phone!=true" class="message">{{formCheckTip.phone}}</span>
                         </td>
                    </tr>

                    <tr class="row3">
                         <td class="td_title"><span><i>*</i>密码：</span></td>
                         <td>
                              <el-input type="password" placeholder="请输入密码" v-model="subAccountForm.passw"
                                   @input="watchContent('passw')" @blur="checkDo('passw')" autocomplete="new-password">
                              </el-input>
                         </td>
                         <td>
                              <span v-show="formCheckTip.passw!=true" class="message">{{formCheckTip.passw}}</span>
                         </td>
                    </tr>
                    <tr>
                         <td></td>
                         <td class="tip">请设置6-20位字母、数字或符号组成的密码</td>
                    </tr>
                    <tr class="row4">
                         <td class="td_title"><i>*</i><span>确认密码：</span></td>
                         <td>
                              <el-input type="password" placeholder="请再次输入密码" v-model="subAccountForm.passwCon"
                                   @input="watchContent('passwCon')" @blur="checkDo('passwCon')">
                              </el-input>
                         </td>
                         <td>
                              <span v-show="formCheckTip.passwCon!=true"
                                   class="message">{{formCheckTip.passwCon}}</span>
                         </td>
                    </tr>
                    <tr>
                         <td></td>
                         <td class="tip">请设置6-20位字母、数字或符号组成的密码</td>
                    </tr>

                    <tr class="row5">
                         <td class="td_title"><span>名称：</span></td>
                         <td>
                              <el-input placeholder="请输入账号名称" v-model="subAccountForm.name" @input="watchContent(2)"
                                   maxlength="20" show-word-limit>
                              </el-input>
                         </td>
                    </tr>
                    <tr>
                         <td></td>
                         <td class="confirmCreate">
                              <div class="confirmBtn" @click="createBtn">确认创建</div>
                         </td>
                    </tr>

               </table>
          </div>
     </div>
</template>
<script>
     import { ref, getCurrentInstance, reactive } from 'vue';
     import { useRouter } from 'vue-router';
     import { ElMessage } from 'element-plus'
     export default {
          setup() {
               const { proxy } = getCurrentInstance()
               const router = useRouter()
               const L = proxy.$getCurLanguage
               const subAccountForm = reactive({
                    phone: '',
                    passw: '',
                    passwCon: '',
                    name: ''
               })

               const formCheckTip = reactive({
                    phone: '',
                    passw: '',
                    passwCon: '',
               })

               //确认end
               const watchContent = (type) => {
                    formCheckTip[type] = ''
               }


               const checkDo = (label) => {
                    switch (label) {
                         case 'phone': {
                              formCheckTip.phone = proxy.$checkPhone(subAccountForm.phone)
                              break
                         }
                         case 'passw': {
                              formCheckTip.passw = proxy.$checkPwd(subAccountForm.passw)
                              break
                         }
                         case 'passwCon': {
                              if (!subAccountForm.passwCon) {
                                   formCheckTip.passwCon = '请再次输入密码'
                              } else if (subAccountForm.passwCon.trim() != subAccountForm.passw.trim()) {
                                   formCheckTip.passwCon = '确认密码不一致'
                              } else {
                                   formCheckTip.passwCon = true
                              }
                              break
                         }

                    }
               }

               const createBtn = () => {
                    if (proxy.$checkPhone(subAccountForm.phone) != true) {
                         formCheckTip.phone = proxy.$checkPhone(subAccountForm.phone)
                         return
                    }

                    if (proxy.$checkPwd(subAccountForm.passw) != true) {
                         formCheckTip.passw = proxy.$checkPwd(subAccountForm.passw)
                         return
                    }

                    if (!subAccountForm.passwCon) {
                         formCheckTip.passwCon = '请再次输入密码'
                         return
                    } else if (subAccountForm.passwCon.trim() != subAccountForm.passw.trim()) {
                         formCheckTip.passwCon = '确认密码不一致'
                         return
                    }

                    let param = {
                         memberMobile: subAccountForm.phone.trim(),
                         loginPwd: subAccountForm.passw.trim(),
                         confirmPwd: subAccountForm.passwCon.trim(),
                         designerName: subAccountForm.name.trim()
                    }
                    proxy.$post('v3/member/front/enterprise/designer/add', param).then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                              router.replace('/member/subAccount')
                         } else {
                              ElMessage(res.msg)
                         }
                    })

               }


               return {
                    L,
                    subAccountForm,
                    formCheckTip,
                    checkDo,
                    watchContent,
                    createBtn,

               }
          }
     }
</script>

<style lang="scss">
     .flex-col {
          display: flex;
          flex-direction: column;
     }

     .el-input__inner {
          line-height: 1px !important;
     }

     .flex-row {
          display: flex;
          flex-direction: row;
     }

     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
          -webkit-appearance: none;
     }

     .subAccount {

          float: left;
          width: 1007px;
          background-color: #fff;
          margin-left: 15px;

          .title {
               height: 60px;
               border-bottom: 1px solid #EEEEEE;
               line-height: 60px;
               padding-left: 20px;
               font-size: 16px;
               font-family: SourceHanSansCN-Medium, SourceHanSansCN;
               font-weight: 500;
               color: #666666;
          }

          .create_sub_acc_con {
               padding: 20px;
               padding-left: 100px;
          }
     }

     #popTable {

          border-spacing: 10px;

          .td_title {
               text-align: right;
               font-size: 14px;
               font-family: SourceHanSansCN-Regular, SourceHanSansCN;
               font-weight: 400;
               color: #666666;
          }

          .tip {
               color: #C0C4CC;
          }

          .el-input {
               width: 338px;
          }

          .el-input__inner {
               width: 338px;
               font-size: 13px;
               padding-right: 0;
               border-radius: 2px;
               padding-bottom: 1px;

          }

          i {
               color: #e2231a;
               font-style: normal;
          }

          .message {
               font-size: 12px;
               line-height: 2;
               padding-top: 4px;
               color: #e2231a;
               font-family: Microsoft YaHei;
          }

          td {
               max-width: 338px;

               &.title {
                    padding-top: 9px;
               }
          }

          .confirmBtn {
               width: 100px;
               height: 38px;
               background: #285FDE;
               text-align: center;
               line-height: 38px;
               color: #fff;
               margin-top: 30px;
               cursor: pointer;
          }
     }
</style>